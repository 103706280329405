<script>
import ko from "knockout";
import "devexpress-reporting/dx-webdocumentviewer";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import $ from "jquery";
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import { authHeader } from "@/helpers/authservice/auth-header";
ajaxSetup.ajaxSettings = {
  headers: authHeader(),
};

export default {
  name: "WebDocumentViewer",
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    this.fillReports();
    this.applyReport();
  },
  beforeUnmount() {
    ko.cleanNode(this.$refs.viewer);
  },

  data() {
    return {
      title: "Отчеты",
      items: [
        {
          text: "Главная",
          href: "/",
        },
        {
          text: "Отчеты",
          active: true,
        },
      ],

      selectedReport: null,
      reports: [],

      callbacks: {
        CustomizeLocalization: function (s, e) {
          e.LoadMessages($.get(`${process.env.VUE_APP_URL}/content/dx-analytics-core.ru.json`)); // Load files obtained from DevExpress Localization Service.
          e.LoadMessages($.get(`${process.env.VUE_APP_URL}/content/dx-reporting.ru.json`)); // Load files obtained from DevExpress Localization Service.
          s.UpdateLocalization({
            "Waiting for parameter values...": "Ожидание ввода данных...",
          });
        },
        CustomizeParameterEditors: function (s, e) {
          if (e.parameter.type === "System.DateTime") {
            e.info.editor = $.extend({}, e.info.editor);
            e.info.editor.extendedOptions = $.extend(e.info.editor.extendedOptions || {}, { type: "date" });
          }
        },
      },
    };
  },

  methods: {
    async fillReports() {
      try {
        var u = `${process.env.VUE_APP_URL}/api/reports`;
        var response = await $.get({
          url: u,
          headers: authHeader(),
        });
        var data = response;
        this.reports = data;
      } catch (error) {
        console.log(error.response);
      }
    },
    setViewerOptions(report) {
      if (!report) return;
      this.viewerOptions = {
        reportUrl: ko.observable(`${report}`), // The URL of a report.
        requestOptions: {
          host: process.env.VUE_APP_URL + "/",
          authHeader: authHeader(),
          invokeAction: "DXXRDV",
        },
        callbacks: this.callbacks,
      };
    },
    applyReport(report) {
      this.setViewerOptions(report);
      ko.cleanNode(this.$refs.viewer);
      ko.applyBindings(this.viewerOptions, this.$refs.viewer);
    },

    onReportChanged() {
      this.applyReport(this.selectedReport);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card">
      <div class="card-body">
        <DxSelectBox
          ref="cmbReports"
          v-model="selectedReport"
          @value-changed="onReportChanged"
          display-expr="value"
          value-expr="key"
          placeholder="Выбрать отчет..."
          :data-source="reports"
          :show-clear-button="true"
          class="w-100"
        ></DxSelectBox>
      </div>
    </div>

    <div class="card">
      <div class="card-body" style="height: 100vh">
        <div ref="viewer" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0" data-bind="dxReportViewer: $data"></div>
      </div>
    </div>
  </Layout>
</template>
